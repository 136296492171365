import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Button, Form, Spinner, InputGroup, Row, Col } from 'react-bootstrap'
import { message, Modal } from 'antd'

import { getSettings } from '../../Settings/services'
import { storeAuthorization } from '../services'
import { handleError } from '../../../helpers'

export default function NewAuthorizationNoSyncModal({ visible, onClose, delivery, reload }) {
	const { user } = useSelector(state => state.auth)
	const [options, setOptions] = useState(null)
	const [loading, setLoading] = useState(false)
	const { handleSubmit, register, errors } = useForm({})

	useEffect(() => {
		!options && getSettings({ 'filter[key]': 'delivery_types' })
			.then(res => {
				setOptions(res.data.data.filter(setting => setting.key === 'delivery_types'))
			})
			.catch(handleError)
	}, [ options ])
	
	const onSubmit = async values => {
		setLoading(true)
		try {
			await storeAuthorization({
				delivery_id: delivery.id,
				responsable_name: user.name,
				...values
			})
		} catch (error) {
			message.error(`No se pudo almacenar autorización ${values.authorization_key}`)
			setLoading(false)
			return
		}

		message.success('Autorización vinculada exitosamente.')
		reload()
		onClose()
	}
	
	return (
		<React.Fragment>
			<Modal open={visible} onCancel={onClose} footer={false}>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Form.Group>
						<Form.Label>Número de Autorización <span className='text-danger'>*</span></Form.Label>
						<InputGroup>
							<Form.Control 
								type="number"
								name="authorization_key"
								ref={register({ required:true })}
								placeholder="Escriba aquí unicamente números"
							/>
						</InputGroup>
						{ errors.authorization_key && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
					</Form.Group>
					<Row>
						<Form.Group as={Col}>
							<Form.Label>Tiempo de Autorizacion (dias) <span className='text-danger'>*</span></Form.Label>
							<Form.Control 
								type="number"
								name="authorization_days"
								ref={register({ required:true })}
							/>
							{ errors.authorization_days && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>Tipo de Entrega <span className='text-danger'>*</span></Form.Label>
							<Form.Control
								as="select"
								name="type"
								ref={register({ required: true })}
							>
								<option value="">:: Seleccione una opción ::</option>
								{ options && options.map(opt => (
									<option key={opt.id} value={opt.value}>{opt.value}</option>
								)) }
							</Form.Control>
							{ errors.type && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col}>
							<Form.Label>PLU <span className='text-danger'>*</span></Form.Label>
							<Form.Control 
								name="plu"
								ref={register({ required:true })}
							/>
							{ errors.plu && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>PV <span className='text-danger'>*</span></Form.Label>
							<Form.Control 
								name="pv"
								ref={register({ required:true })}
							/>
							{ errors.pv && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col}>
							<Form.Label>Cantidad Solicitada <span className='text-danger'>*</span></Form.Label>
							<Form.Control 
								name="quantity_requested"
								ref={register({ required:true })}
							/>
							{ errors.quantity_requested && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>Cantidad Entregada <span className='text-danger'>*</span></Form.Label>
							<Form.Control 
								name="quantity_delivered"
								ref={register({ required:true })}
							/>
							{ errors.quantity_delivered && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
						</Form.Group>
					</Row>
					<Form.Group>
						<Form.Label>Nombres Medicamentos <span className='text-danger'>*</span></Form.Label>
						<Form.Control 
							name="medicine_name"
							ref={register({ required:true })}
						/>
						{ errors.medicine_name && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
					</Form.Group>
					<hr />
					<Button type="submit" disabled={loading}>
						{ loading && <Spinner animation='border' size='sm' className='mr-5' /> }
						Agregar Autorización
					</Button>
				</Form>
			</Modal>
		</React.Fragment>
	)
}