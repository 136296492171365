import React, {useState, useEffect} from 'react'
import { Drawer, message, Popconfirm } from 'antd'
import { Spinner, Button } from 'react-bootstrap'

import SendSMS from './SendSMS'
import TicketDetails from './TicketDetails'
import {getTicket, updateTicket} from '../services'
import { handleError } from '../../../helpers'

export default function TicketDrawer({ drawerId, closeDrawer }) {
   const [ticket, setTicket] = useState(null)   

   useEffect(() => {
      !ticket && getTicket(drawerId, { include: 'user,ticketType,responses' })
         .then(res => setTicket(res.data.data))
         .catch(error => handleError(error))
   }, [ticket, drawerId])

   const handleChange = newState => {
      updateTicket(drawerId, {state: newState})
         .then(response => {
            message.success(`Ok. ${response.data.message}`)
            setTicket(null)
         })
         .catch(error => handleError(error))
   }   

   return (
      <Drawer
         title="Detalles de Ticket de Soporte"
         placement="right"         
         onClose={()=>{
            setTicket(null)
            closeDrawer()
         }}
         open={drawerId!==null}
         width={450}         
      >
         {ticket ? (
            <React.Fragment>
               <TicketDetails ticket={ticket} />
               <div className="mt-20">
                  { ticket.state !== 'pendding' && (
                     <Popconfirm
                        cancelText="Cancelar"
                        okText="Confirmar"
                        title="Cambiar a estado: Pendiente"
                        onConfirm={()=>handleChange('pendding')}
                     >
                        <Button className="mr-15">Marcar como Pendiente</Button>
                     </Popconfirm>
                  )}
                  { ticket.state !== 'solved' && (
                     <Popconfirm
                        cancelText="Cancelar"
                        okText="Confirmar"
                        title="Cambiar a estado: Cerrado"
                        onConfirm={()=>handleChange('solved')}
                     >
                        <Button className="mr-15">Marcar como Cerrado</Button>
                     </Popconfirm>
                  )}
                  { ticket.state !== 'open' && (
                     <Popconfirm
                        cancelText="Cancelar"
                        okText="Confirmar"
                        title="Cambiar a estado: Abierto"
                        onConfirm={()=>handleChange('open')}
                     >
                        <Button className="mr-15">Marcar como Abierto</Button>
                     </Popconfirm>
                  )}
               </div>
               <div className="mt-20">
                  <SendSMS 
                     ticketId={ticket.id}
                     user={ticket.user} 
                     reload={()=>setTicket(null)}
                  />
               </div>
            </React.Fragment>
         ) : (
            <Spinner animation="grow" />
         )}         
      </Drawer>
   )
}